import { Deserializable } from "./deserializable.model";

export class Token implements Deserializable {
	
	private access_token: string
	private token_type: string
	private expires_in: any
	private setDate: any

	public constructor(
		
	) {}

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getAccessToken() {
		return this.access_token;
	}

	getTimestamp(){
		return this.setDate
	}

	isTokenExpired(){

		let now = Math.floor(Date.now() / 1000);

		if((now - this.setDate > this.expires_in)){

			return true

		}else{

			return false

		}

	}

}